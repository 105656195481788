import React, { useState } from "react";
import classnames from "classnames";

import './style.scss';

const defaultValues = {
    float: '',
    money: '|RUB',
    objectElem: {
        VALUE: ''
    }
};

const transformFunction = {
    float: val => +val,
    money: val => val,
    objectElem: (val, oldVal) => ({...oldVal, VALUE: val}),
};

const validFunction = {
    money: val => {
        const priceArr = val.split('|');
        return priceArr.length === 2 && priceArr[0].length > 0;
    }
}

const ItemWrapper = ({field, value, changeHandler, deleteHandler, itemProps, ItemComponent}) => {
    const [showDelete, setShowDelete] = useState(false);
    const showDeleteButton = () => {
        setShowDelete(true);
    }
    const hideDeleteButton = () => {
        setShowDelete(false);
    }

    const onRender = target => {
        target.value === '' && target.focus();
    }

    const deleteElem = () => {
        deleteHandler && deleteHandler();
    };

    return (
        <div className="array-of__item" onMouseEnter={showDeleteButton} onMouseLeave={hideDeleteButton}>
            <ItemComponent
                field={field}
                value={typeof value === 'number' ? (value + '') : value}
                change={val => changeHandler(val)}
                onRender={onRender}
                {...itemProps}
            />
            {showDelete && (
                <button onClick={deleteElem} type="button" className="delete"></button>
            )}
        </div>
    )
}

const Component = ({ field, label, t, value, ItemComponent, change, classname, itemProps, valueType }) => {
    const [current, setCurrent] = useState(value || []);

    const changeHandler = (val, i) => {
        const newCurrent = [...current];
        if (validFunction[valueType] && !validFunction[valueType](val)) {
            return;
        }
        newCurrent[i] = valueType ? transformFunction[valueType](val, newCurrent[i]) : val;
        setCurrent(newCurrent);
        change(newCurrent, field);
    }

    const deleteHandler = (i) => {
        const newCurrent = [...current];
        const newValue = [...current];
        newCurrent.splice(i, 1);
        setCurrent(newCurrent);
        newValue[i] = valueType === 'objectElem' ? {...newValue[i], VALUE: ''} : '';
        change(newValue, field);
    };

    const addElem = () => {
        setCurrent([...current, defaultValues[valueType]]);
    }

    return (
        <div className={classnames('array-of', {[classname]: classname})}>
            <div className="array-of__list">
                {current.map((value, key) => (
                    <ItemWrapper
                        field={`${field}.${key}`}
                        key={key}
                        value={valueType === 'objectElem' ? value.VALUE : value}
                        changeHandler={(val) => changeHandler(val, key)}
                        itemProps={itemProps}
                        ItemComponent={ItemComponent}
                        deleteHandler={() => deleteHandler(key)}
                    />
                ))}
            </div>
            <div onClick={addElem} className="array-of__add">
                <span className="pseudolink">{t('add')} {label}</span>
            </div>
        </div>
    );
}

export default Component;
