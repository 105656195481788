import React from "react";
import TextField from "../TextField";

const PriceInput = ({value, field, change, onRender}) => {
    return (
        <TextField
            type="string"
            value={value}
            formatter={val => {
                const separatorData = val.match(/[.,]/);
                if (separatorData) {
                    val = val.substring(0, separatorData.index).replace(/\D/g, '')
                        + '.' + val.substring(separatorData.index + 1, separatorData.index + 3).replace(/\D/g, '');
                } else {
                    val = val.replace(/\D/g, '');
                }
                val = val.replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
                return val;
            }}
            saveFormatter={(val) => val.replace(/\s/g, '')}
            change={(val) => {
                change(val, field);
            }}
            onRender={onRender}
        />
    )
};

PriceInput.defaultProps = {

};

PriceInput.propTypes = {

};

export default PriceInput;