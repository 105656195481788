import React from "react";
import TextField from "../TextField";

const IntegerInput = ({value, change, field}) => {
    return (
        <TextField
            type="string"
            value={value}
            formatter={(val) => {
                let positive = true;
                if (val.startsWith('-')) {
                   positive = false;
                   val = val.substring(1);
                }
                val = val.replace(/\D/g, '');
                return (positive ? '' : '-') + val;
            }}
            change={(val) => {
                change(val, field);
            }}
        />
    )
};

IntegerInput.defaultProps = {

};

IntegerInput.propTypes = {

};

export default IntegerInput;