import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import noop from "lodash/noop";

const Icon = ({visible, type, inheritColor, inheritSize, className, ...props}) => {
    if (!visible) {
        return null;
    }

    return (
        <svg
            className={classNames(
                {
                    'icon': true,
                    'inherit-color': !!inheritColor,
                    'inherit-size': !!inheritSize
                },
                className
            )}
            {...props}
        >
            <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref={`#icon-${type}`}/>
        </svg>
    )
};

Icon.defaultProps = {
    visible: true,
    inheritColor: false,
    inheritSize: false,
    onClick: noop,
};

Icon.propTypes = {
    visible: PropTypes.bool,
    inheritColor: PropTypes.bool,
    inheritSize: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
    type: PropTypes.oneOf([
        `star`,
        `chevron`,
        `phone`,
        `info`,
        `arrow-down`,
        `arrow`,
        `tick`
    ]).isRequired,
};

export default Icon;
