import bxApi from "./index";

class BxApi {
  static _bx = window["BX24"];

  static send = true;

  test = false;

  apix = {
    entity: "bx24asterisk",
  };

  personal = {
    entity: "bxA_personal",
    code: {
      userFrequency: "Frequency",
    },
  };

  getQueryCall = () => {
    return queries;
  };

  punto(string) {
    const enToRu = {
      q: "й",
      w: "ц",
      e: "у",
      r: "к",
      t: "е",
      y: "н",
      u: "г",
      i: "ш",
      o: "щ",
      p: "з",
      "[": "х",
      "]": "ъ",
      a: "ф",
      s: "ы",
      d: "в",
      f: "а",
      g: "п",
      h: "р",
      j: "о",
      k: "л",
      l: "д",
      ";": "ж",
      "'": "э",
      z: "я",
      x: "ч",
      c: "с",
      v: "м",
      b: "и",
      n: "т",
      m: "ь",
      ",": "б",
      ".": "ю",
      "/": ".",
    };
    const NewString = [];
    let i, s;

    for (i = 0; i < string.length; i++) {
      s = string.substr(i, 1);

      NewString.push(s in enToRu ? enToRu[s] : s);
    }

    return NewString.join("");
  }

  crmEntityUpdate = (type, id, fields) => {
    const method = ["crm", type.toLowerCase(), "update"].join(".");
    return this.rest(method, { id, fields });
  };

  saveEntityDataUser = (name, user, createEntity = true) => {
    const method = `entity.item.${"id" in user ? "update" : "add"}`;
    const post = {
      ENTITY: this.personal.entity,
      CODE: this.personal.code.userFrequency,
      NAME: name,
      PREVIEW_TEXT: user.favorite || 0,
      SORT: user.frequency,
    };

    if (user["id"]) post.ID = user["id"];

    return new Promise((resolve, reject) => {
      this.rest(method, post)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          if (createEntity && err)
            if ("error" in err)
              switch (err.error) {
                case "ERROR_ENTITY_NOT_FOUND":
                  this.rest("entity.add", {
                    NAME: this.personal.entity,
                    ENTITY: this.personal.entity,
                  }).then(() => {
                    resolve(this.saveEntityDataUser(name, user, false));
                  });
                  break;
                default:
                  break;
              }

          reject(err);
        });
    });
  };

  getEntityDataUser = () => {
    return new Promise((resolve, reject) => {
      this.rest("entity.item.get", {
        ENTITY: this.personal.entity,
        FILTER: {
          CODE: this.personal.code.userFrequency,
        },
      })
        .then((items) => {
          const data = {};

          items.forEach(({ ID, NAME, SORT, PREVIEW_TEXT }) => {
            data[NAME] = {
              id: ID,
              favorite: PREVIEW_TEXT ? parseInt(PREVIEW_TEXT) : 0,
              frequency: parseInt(SORT),
            };
          });

          resolve(data);
        })
        .catch((err) => reject(err));
    });
  };

  currentUser = () => {
    return this.rest("user.current");
  };

  call = (request, callback) => {
    BxApi._bx.placement.call(request, {}, function (result) {
      callback && callback(result);
    });
  }

  searchUser = (string) => {
    string = string.toLowerCase();
    const punto = this.punto(string);
    const batch = {
      string: [
        "user.search",
        {
          FILTER: {
            FIND: string.replace(/[^а-яА-Я\w\s-]/g, ""),
            ACTIVE: true,
          },
        },
      ],
    };

    if (string !== punto) {
      batch.punto = [
        "user.search",
        {
          FILTER: {
            FIND: punto.replace(/[^а-яА-Я\w\s-]/g, ""),
            ACTIVE: true,
          },
        },
      ];
    }

    if (/^\s*\d+\s*$/.test(string)) {
      batch.number = [
        "user.get",
        {
          fields: [
            "ID",
            "UF_PHONE_INNER",
            "PERSONAL_MOBILE",
            "WORK_PHONE",
            "PERSONAL_PHONE",
            "NAME",
            "SECOND_NAME",
            "LAST_NAME",
          ],
          FILTER: {
            UF_PHONE_INNER: "%" + string + "%",
            ACTIVE: true,
          },
        },
      ];
    }

    return this.batch(batch).then((data) => {
      const mergeResult = [];

      ["string", "punto", "number"].forEach((res) => {
        if (res in data)
          data[res].forEach((user) => {
            const u = mergeResult.filter((u) => u.ID === user.ID);

            if (u.length < 1) mergeResult.push(user);
          });
      });

      return mergeResult;
    });
  };

  callData = () => {
    return new Promise((resolve, reject) => {
      if (this.test)
        return resolve({
          options: {
            CRM_ENTITY_ID: 1,
            CRM_ENTITY_TYPE: "LEAD",
          },
        });

      if (!this._isBx()) return reject({ error: "undefined bx connector" });

      let i = 0;
      const getData = () => {
        const data = BxApi._bx.placement.info();

        if (++i > 15 || (data && data["options"] && data["placement"])) {
          return resolve(data);
        }

        setTimeout(() => {
          getData();
        }, 100);
      };

      getData();
    });
  };

  getDomain = () => {
    if (!this._isBx())
      return new Promise((resolve, reject) => {
        reject({ error: "undefined bx connector" });
      });

    if (this.test) return "localhost";

    return BxApi._bx.getDomain();
  };

  rest = (method, params) => {
    if (this.test) {
      console.log("REST", method, params);

      return fetch("json/" + method + ".json")
        .then((res) => {
          return res.json();
        })
        .then((data) => {
          return data.result;
        });
    }

    if (!this._isBx())
      return new Promise((resolve, reject) => {
        reject({ error: "undefined bx connector" });
      });

    const endSend = queries.add(method, params);
    return new Promise((resolve, reject) => {
      BxApi._bx.callMethod(method, params, (response) => {
        this._responseStatus(response, resolve, reject);
        endSend();
      });
    });
  };

  getLang = BxApi._bx.getLang;
  init = bxApi._bx.init;

  batch = (methods) => {
    if (this.test) {
      console.log("BATCH", methods);

      return fetch("json/batch.json")
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          return json.result.result;
        });
    }

    if (!this._isBx())
      return new Promise((resolve, reject) => {
        reject({ error: "undefined bx connector" });
      });

    if (!BxApi.send)
      return new Promise((resolve, reject) => {
        reject({ error: "before close window" });
      });

    const endSend = queries.add("batch", methods);
    return new Promise((resolve) => {
      // setTimeout(() => {
      BxApi._bx.callBatch(methods, (response) => {
        const result = {};

        for (let i in response)// eslint-disable-line
          this._responseStatus(
            response[i],
            (data) => {
              result[i] = data;
            },
            (err) => {
              result[i] = err;
            }
          );

        endSend();
        resolve(result);
      });
      // }, 1400)
    });
  };

  bindEvents = (event, callback) => {
    BxApi._bx.placement.bindEvent(event, function (callState) {
      callback(callState);
    });
  }

  _responseStatus = (response, resolve, reject) => {
    if (this.test) {
      console.log("Response", response);
      resolve(response.result);
      return;
    }

    switch (response.status) {
      case 200:
        resolve(response.answer.result);
        break;
      default:
        if ("answer" in response && "error" in response.answer) {
          if (this.test)
            console.log("Response Error", {
              error: response.answer.error,
              desc: response.answer.error_description,
            });
          reject({
            error: response.answer.error,
            desc: response.answer.error_description,
          });
        } else {
          reject(response);
        }
    }
  };

  _isBx() {
    const result = this.test
      ? true
      : BxApi._bx !== null && typeof BxApi._bx === "object";

    if (!result) window.close();

    return result;
  }
}

const queries = new (function () {
  const global = {};
  const query = { length: 0 };
  let sended = 0;

  const getSendCount = () => {
    return query.length;
  };

  const addSend = (url, post = false) => {
    const key = "sendN_" + sended++;
    query[key] = { url, post };
    query.length++;

    return () => {
      if (key in query) {
        delete query[key];
        query.length--;

        if (!query.length) global.listenerClose(query.length);
      }
    };
  };

  const onEmptyQuery = () => {};

  const proxy = new Proxy(query, {// eslint-disable-line
    set(oldObj, prop, value) {
      if (prop === "length") {
        global.listenerClose(value);
      }

      return true;
    },
  });

  global.add = addSend;
  global.getLength = getSendCount;
  global.listenerClose = onEmptyQuery;

  return global;
})();

export default BxApi;
