import React from "react";

const Checkbox = ({value, field, formatter, ...props}) => {
    return (
        <input type="checkbox" checked={value === formatter[0]} onChange={(e) => {
            props.change(e.target.checked ? formatter[0] : formatter[1], field);
        }} />
    )
};

Checkbox.defaultProps = {

};

Checkbox.propTypes = {

};

export default Checkbox;
