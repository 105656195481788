import React, { Component } from 'react'

class Wait extends Component
{
    render() {
        return (
            <div className="right">
                <div className="right__block big-loader">
                    <h1>{this.props.label}</h1>

                    <div className="loadingProgress">
                        <div className="loadingProgressG"/>
                    </div>
                </div>
            </div>
        )
    }

}

export default Wait;