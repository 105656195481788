import React from "react";
import classnames from 'classnames';

const FormField = ({label, children, up}) => {
    const rootClassname = classnames('form-field', { '_up': up })
    return (
        <div className={rootClassname}>
            <div className="form-field__label">{label}</div>
            <div className="form-field__field">
                {children}
            </div>
        </div>
    )
};

FormField.defaultProps = {

};

FormField.propTypes = {

};

export default FormField;