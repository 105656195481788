import React from "react";
import ReactSelect from "react-select";
import classnames from 'classnames';

class Select extends React.Component {
    state = {
        notEmpty: false,
    };

    onFocus = (e) => {
        this.setState({ notEmpty: true });

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    onBlur = (e) => {
        if (e.target.value === "") {
            this.setState({ notEmpty: false });
        }
    };

    onChange = (data) => {
        const { change, field, multiple } = this.props;

        this.onFocus();
        const value = multiple ? (data || [{value: -1}]).map(({value}) => value) : Array.isArray(data) ? data[0].value : data.value;
        change(value, field);
    };

    componentDidMount() {
        this.setState({ notEmpty: !!this.props.value });
    }

    render() {
        const { field, value, options = [], multiple = false } = this.props;

        const formatOptionLabel = ({ value, label }) => (
            <div className={classnames("select-option-label", { "_grey": value === 'null' })}>
                {label}
            </div>
        );

        const _value =
            multiple
                ? (!value || (value.length === 1 && value[0] === -1)) ? [] : options.filter(option => value.includes(option.value))
                : options.find((option) => value === option.value);

        return (
            <ReactSelect
                name={field}
                value={_value}
                className="form-input__input form-input__input_select"
                id={"column-" + field}
                onFocus={this.onFocus}
                onBlur={this.onBlur}
                onChange={this.onChange}
                options={options}
                placeholder=""
                menuPosition="fixed"
                isMulti={multiple}
                formatOptionLabel={formatOptionLabel}
            />
        );
    }
}

export default Select;
