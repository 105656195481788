import React, { useState, useEffect, useRef } from "react";

const TextField = (props) => {
    const { type, change, field, value, disabled, formatter = val => val, saveFormatter = val => val, onChange: onChangeHandler = () => {}, onRender } = props;
    const [current, setCurrent] = useState(formatter(value || '')  || '');
    const elem = useRef(null);

    const onFocus = (e) => {
        props.onFocus && props.onFocus(e);
    };
    const onBlur = (e) => {
        props.onBlur && props.onBlur(e);
        const saveFormattedValue = saveFormatter(current);
        if (saveFormattedValue !== value) {
            change(saveFormattedValue, field);
        }
    };
    const onChange = (e) => {
        const formattedValue = formatter(e.target.value) || ``;
        setCurrent(formattedValue);
        if (formattedValue !== value) {
            change(formattedValue, field);
        }
        onChangeHandler(e.target);
    };

    useEffect(() => {
        onRender && onRender(elem.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        setCurrent(formatter(value || '')  || '');
    }, [value]);

    return type === 'textarea' ? (
        <textarea
            name={field}
            value={current}
            onChange={onChange}
            className="form-input__input"
            id={"column-" + field}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            rows="4"
            ref={elem}
        />
    ) : (
        <input
            name={field}
            value={current}
            onChange={onChange}
            className="form-input__input"
            type="text"
            id={"column-" + field}
            onFocus={onFocus}
            onBlur={onBlur}
            disabled={disabled}
            ref={elem}
        />
    );
}

export default TextField;
