import _ from "lodash";

class Mixin
{
    objFilter(obj, predicate) {
        const result = {};
        let key;

        for (key in obj) {
            if (obj.hasOwnProperty(key) && predicate(obj[key], key, obj)) {
                result[key] = obj[key];
            }
        }

        return result;
    }

    getObjectDiff(obj1, obj2) {
        const diff = Object.keys(obj1).reduce((result, key) => {
            if (!obj2.hasOwnProperty(key)) {
                result.push(key);
            } else if (_.isEqual(obj1[key], obj2[key])) {
                const resultKeyIndex = result.indexOf(key);
                result.splice(resultKeyIndex, 1);
            }
            return result;
        }, Object.keys(obj2));

        return diff;
    }
}

export default Mixin;