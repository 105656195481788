import React, { Component } from "react";
import classNames from "classnames";

class Input extends Component {
    state = {
        notEmpty: false,
    };

    changeArr = (e) => {
        this.onFocus();

        const { field, value, change, saveFormatter } = this.props;

        const keys = e.target.name.split(".");

        value[keys[1]]["VALUE"] = saveFormatter ? saveFormatter(e.target.value) : e.target.value;
        // value[keys[1]]['VALUE_TYPE'] = e.target.value;
        change(value, field);
    };

    onFocus = (e) => {
        this.setState({ notEmpty: true });

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    onBlur = (e) => {
        if (e.target.value === "") {
            this.setState({ notEmpty: false });
        }

        if (this.props.onFocus) {
            this.props.onFocus(e);
        }
    };

    onChange = (e) => {
        const { change, field, saveFormatter } = this.props;
        this.onFocus();
        change(saveFormatter ? saveFormatter(e.target.value) : e.target.value, field);
    };

    onInput = (e) => {
        const { onInput } = this.props;
        if (onInput) onInput(e.target);
    }

    componentDidMount() {
        this.setState({ notEmpty: !!this.props.value });
    }

    render() {
        const { change, field, label, value, type, disabled, formatter } = this.props;
        const { notEmpty } = this.state;

        if (type === "array") {
            if (!value) change([{ VALUE: "" }], field);

            return (
                <div
                    className={classNames("form-group", {
                        "form-input--not-empty": notEmpty,
                    })}
                >
                    {(value || [{ VALUE: "" }]).map(
                        ({ VALUE }, key) => (
                            <Input
                                field={`${field}.${key}`}
                                label={label}
                                value={formatter ? formatter(VALUE) : VALUE}
                                key={key}
                                change={this.changeArr}
                                disabled={disabled}
                            />
                        )
                    )}
                </div>
            );
        } else
            return (
                <div
                    className={classNames("form-input", {
                        "form-input--not-empty": notEmpty,
                    })}
                >
                    <div className="form-input__control">
                        {label && (
                            <label
                                htmlFor={"column-" + field}
                                className="form-input__label"
                            >
                                {label}
                            </label>
                        )}
                        <input
                            name={field}
                            value={value ? formatter ? formatter(value) : value : ''}
                            onChange={this.onChange}
                            className="form-input__input"
                            type="text"
                            id={"column-" + field}
                            onFocus={this.onFocus}
                            onBlur={this.onBlur}
                            onInput={this.onInput}
                            disabled={disabled}
                        />
                    </div>
                </div>
            );
    }
}

export default Input;
