import React from "react";
import noop from "lodash/noop";

import Input from "../../elements/input";
import BxApi from "../../services/bx-api";

const bxApi = new BxApi();
class CompaniesOfContact extends React.Component {
    state = { companies: {} };

    componentDidMount() {
        this.getCompanyInfo();
    }

    getCompanyInfo = async () => {
        const { id } = this.props;
        let companiesIds = [];

        const FIELD_NAMES = ["UF_CRM_1614261357", "UF_CRM_1602514183"];
        const contactData = await bxApi.rest("crm.contact.get", {
            id,
        });

        FIELD_NAMES.forEach((FIELD_NAME) => {
            if (Array.isArray(contactData[FIELD_NAME])) {
                const customFieldValues = contactData[FIELD_NAME].filter(
                    (id) => typeof id === "string" && id.startsWith("CO_")
                ).map((id) => ({
                    COMPANY_ID: id.slice(3),
                }));
                companiesIds.push(...customFieldValues);
            }
        });

        const realCompanies = await bxApi.rest(
            "crm.contact.company.items.get",
            {
                id,
            }
        );
        companiesIds.push(...realCompanies);

        const companies = await Promise.all(
            companiesIds.map(({ COMPANY_ID }) => {
                try {
                    return bxApi.rest("crm.company.get", {
                        id: COMPANY_ID,
                    });
                } catch (e) {
                    console.log("crm.contact.company.items.get FAILED");
                    return {};
                }
            })
        );

        this.setState({ companies });
    };

    render() {
        const { label } = this.props;
        const { companies } = this.state;

        return (
            <React.Fragment>
                {Array.isArray(companies) &&
                    companies.map(({ TITLE, ID }, key) => (
                        <Input
                            value={TITLE}
                            key={key}
                            label={key === 0 ? label : undefined}
                            disabled
                            change={noop}
                            field={`company-${ID}`}
                        />
                    ))}
            </React.Fragment>
        );
    }
}

export default CompaniesOfContact;
