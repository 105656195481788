import React from 'react'

const {
    Provider: TransferContextProvider,
    Consumer: TransferContextConsumer
} = React.createContext();



const TransferConsumer = (View) => {
    return (props) => {
        return (<TransferContextConsumer>
            {(Props) =>
                <View {...props} transfer={{...Props}}/>
            }
        </TransferContextConsumer>)
    }
};

export {TransferContextProvider, TransferConsumer};