import React from "react";
import noop from "lodash/noop";

import Input from "../../elements/input";
import BxApi from "../../services/bx-api";
// import { string } from "prop-types";

const bxApi = new BxApi();
class CustomField extends React.Component {
    state = { value: null };

    componentDidMount() {
        this.getFieldData();
    }

    getFieldData = async () => {
        const { id, field } = this.props;

        const companiesCollection = await bxApi.rest(
            "crm.contact.company.items.get",
            {
                id,
            }
        );

        const company = companiesCollection[0];
        let companyData = {};
        try {
            companyData = await bxApi.rest("crm.company.get", {
                id: company.COMPANY_ID,
            });
        } catch (e) {
            console.log("crm.contact.company.items.get FAILED");
        }

        this.setState({ value: companyData[field] });
    };

    render() {
        const { label, field } = this.props;
        const { value } = this.state;

        if (!value) {
            return null;
        }

        return (
            <React.Fragment>
                <Input
                    value={value}
                    label={label}
                    disabled
                    change={noop}
                    field={field}
                />
            </React.Fragment>
        );
    }
}

export default CustomField;
