import React from "react";
import TextField from "../TextField";

const FloatInput = ({value, change, field, onChange, onRender}) => {

    return (
        <TextField
            type="string"
            value={value}
            formatter={val => {
                let positive = true;
                if (val.startsWith('-')) {
                    positive = false;
                    val = val.substring(1);
                }
                const separatorData = val.match(/[.,]/);
                if (separatorData) {
                    val = val.substring(0, separatorData.index).replace(/\D/g, '')
                        + '.' + val.substring(separatorData.index + 1, val.length).replace(/\D/g, '');
                } else {
                    val = val.replace(/\D/g, '');
                }
                return (positive ? '' : '-') + val;
            }}
            change={(val) => {
                change(val, field);
            }}
            onChange={onChange}
            onRender={onRender}
        />
    )
};

FloatInput.defaultProps = {

};

FloatInput.propTypes = {

};

export default FloatInput;