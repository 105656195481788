import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import texts from './lang';
import BxApi from "../../services/bx-api";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    en: {
        translation: texts.en
    },
    ru: {
        translation: texts.ru
    },
    la: {
        translation: texts.es
    }
};

let lang;

const bxApi = new BxApi();

bxApi.init(function() {
    try {
        const currentLang = bxApi.getLang();
        if (!currentLang) console.log('current lang is', currentLang);
        lang = ['en', 'ru', 'la'].includes(currentLang) ? currentLang : 'en';
    } catch (e) {
        lang = 'en';
    }

    i18n
        .use(initReactI18next) // passes i18n down to react-i18next
        .init({
            resources,
            lng: lang, // language to use, more information here: https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
            // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
            // if you're using a language detector, do not define the lng option

            interpolation: {
                escapeValue: false // react already safes from xss
            }
        });
})

export default i18n;