import React from "react";

import './styles.scss';

const Link = ({ value, title }) => {
    return (
        <>
            <a className="link" href={`https://pbx2crm.bitrix24.ru/crm/company/details/${value}/`} target="_blank" rel="noopener noreferrer">{title}</a>
        </>
    )
};

export default Link;