import React, { useState } from "react";
import PriceInput from "../../elements/PriceInput";
import Select from "../../elements/Select";

const Money = ({value, field, formatter, currencyList, multiple, onRender, ...props}) => {
    const valueArr = (multiple ? value[0] : value).split('|');
    const [sum, setSum] = useState(valueArr[0]);

    return (
        <>
            <div className="flex-row money">
                <div className="flex-row__elem money__value">
                    <PriceInput
                        value={sum}
                        change={(val) => {
                            setSum(val);
                            props.change(`${val}|${valueArr[1]}`, field);
                        }}
                        onRender={onRender}
                    />
                </div>
                <div className="flex-row__elem money__currency">
                    <Select value={valueArr[1] || null} options={currencyList} change={(val) => {
                        props.change(`${valueArr[0]}|${val}`, field);
                    }} />
                </div>
            </div>
        </>
    )
};

Money.defaultProps = {

};

Money.propTypes = {

};

export default Money;